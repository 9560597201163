import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InternalAPI } from "src/service/internal/Internal";

export const getInternalData = createAsyncThunk(
  "internalData",
  async () => {
    const response = await InternalAPI.getAllPlanning();
    console.log(response.data.data, "response.data.data");

    return { ...response.data.data };
  }
);

const initInternal = {
  profile: {
    fullName: '',
    address: '',
    phoneNumber: '',
    logo: ''
  }
}

const enumDataReducer = createSlice({
  name: "internalData",
  initialState: {},
  reducers: {},
  extraReducers: {
    [getInternalData.fulfilled]: (state, action) => {
      state.profile = action.payload
    },
  },
});

export default enumDataReducer.reducer;
